const filtersModule = {
  firestorePath: 'filters',
  firestoreRefType: 'collection',
  moduleName: 'filtersModule',
  statePropName: 'data',
  namespaced: true,
  sync: {
    preventInitialDocInsertion: true,
  },

  state: {
    filters: {},
  },
  getters: {
    getFilters: (state) => {
      return state.data;
    },
  },
  mutations: {
    UPDATE_FILTERS(state, doc) {
      localStorage.setItem('filters', JSON.stringify(doc));
      Object.assign(state.filters, doc);
    },
  },
};

export default filtersModule;
