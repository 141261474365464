/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// import firebase from 'firebase/app';
// import 'firebase/auth';
import router from '@/router';
//
import {
  getAuth,
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  // sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
  // sendEmailVerification,
  EmailAuthProvider,
  reauthenticateWithCredential,
  // sendEmailVerification,
} from 'firebase/auth';
import { auth, db } from '@/firebase/firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import firebase from 'firebase/compat/app';

import { tooManyRequests } from '@/helpers/too-many-requests';

export default {
  reauthenticate(store, userPassword) {
    const auth = getAuth();
    const credential = EmailAuthProvider.credential(auth.currentUser.email, userPassword);
    return reauthenticateWithCredential(auth.currentUser, credential);
  },
  loginAttempt({ dispatch }, payload) {
    // New payload for login action
    const newPayload = {
      userDetails: payload.userDetails,
      notify: payload.notify,
    };
    // If remember_me is enabled change firebase Persistence
    if (payload.checkbox_remember_me) {
      // Change firebase Persistence

      setPersistence(auth, browserLocalPersistence)
        // If success try to login
        .then(function () {
          dispatch('login', newPayload);
        })

        // If error notify
        .catch(function (err) {
          return payload.notify({
            time: 2500,
            title: 'Error',
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
        });
    } else {
      // Try to login
      setPersistence(auth, browserSessionPersistence)
        // If success try to login
        .then(function () {
          dispatch('login', newPayload);
        })

        // If error notify
        .catch(function (err) {
          return payload.notify({
            time: 2500,
            title: 'Error',
            text: err.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          });
        });
    }
  },
  async login({ commit, state, dispatch }, payload) {
    // dispatch('logout');

    // If user is already logged in notify and exit
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: 'Login Attempt',
        text: 'You are already logged in!',
        color: 'warning',
      });
      return;
    }
    this._vm.$vs.loading({ type: 'corners' });
    signInWithEmailAndPassword(auth, payload.userDetails.email, payload.userDetails.password).then(
      async (result) => {
        this._vm.$vs.loading.close();
        // let guideExists = await firebase.firestore().collection('users').where('userId', '==', result.user.uid).get();
        // if (guideExists.docs.length > 0) {
        //   dispatch('logout');

        //   return payload.notify({
        //     time: 5000,
        //     title: 'Oops!',
        //     text: `This email has already been used on the Recruit a Guide mobile app. Try signing in with another.`,
        //     iconPack: 'feather',
        //     icon: 'icon-alert',
        //     color: 'warning',
        //   });
        // } else {
        let isUsernameUpdateRequired = false;

        // if username is provided and updateUsername FLAG is true
        // set local username update FLAG to true
        // try to update username
        if (payload.updateUsername && payload.userDetails.username) {
          isUsernameUpdateRequired = true;

          dispatch('updateUsername', {
            user: result.user,
            username: payload.userDetails.username,
            notify: payload.notify,
            isReloadRequired: true,
          });
        }
        let employerData = await firebase.firestore().collection('employers').where('employerId', '==', result.user.uid).get();

        // if username update is not required
        // just reload the page to get fresh data
        // set new user data in localstorage

        if (!isUsernameUpdateRequired && employerData.docs.length > 0) {
          if (employerData.docs[0].data().email !== payload.userDetails.email) {
            console.log('Updating email...');
            await firebase.firestore().collection('employers').doc(result.user.uid).update({ email: payload.userDetails.email });
          }

          // router.push(router.currentRoute.query.to || '/pages/dashboard');
          router.push('/pages/dashboard');
          // eslint-disable-next-line no-param-reassign
          result.user.providerData[0].uid = result.user.uid;
          return commit('UPDATE_AUTHENTICATED_USER', result.user.providerData[0]);
        } else {
          // return router.push(router.currentRoute.query.to || '/pages/user-details');
          return router.push('/pages/user-details');
        }
        // }
        // Set FLAG username update required for updating username
      },
      (err) => {
        this._vm.$vs.loading.close();
        let errorMessage = '';
        if (err.message === 'Firebase: Error (auth/user-not-found).') {
          errorMessage = `That Recruit a Guide account doesn't exist. Try a different email address or register an account below.`;
        }
        if (err.message === 'Firebase: Error (auth/wrong-password).') {
          errorMessage = 'The password you entered is incorrect.';
        }
        payload.notify({
          time: 5000,
          title: 'Oops!',
          text: errorMessage,
          iconPack: 'feather',
          icon: 'icon-alert',
          color: 'warning',
        });
      },
    );
    // Try to sigin
  },

  /*   // Google Login
  loginWithGoogle({ dispatch, commit, state, rootState }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning",
      });
      return false;
    }
    const provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (value) => {
        console.log(value);
        const userDetails = {
          email: value.additionalUserInfo.profile.email,
          name: value.additionalUserInfo.profile.given_name,
          surname: value.additionalUserInfo.profile.family_name,
          telephone: "",
        };
        await dispatch(
          "employerModule/createUser",
          {
            cred: value,
            userDetails: userDetails,
          },
          { root: true }
        );
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
    // firebase.auth().onAuthStateChanged(function(user) {
    //     if (user) {
    //         user.updateProfile({
    //             roles: ['admin'],
    //         }).then(function() {
    //             this.$vs.notify({
    //                 title: 'Success',
    //                 text: "done",
    //                 iconPack: 'feather',
    //                 icon: 'icon-check',
    //                 color: 'success'
    //             });
    //         }, function(error) {
    //             this.$vs.notify({
    //                 title: 'Error',
    //                 text: error.message,
    //                 iconPack: 'feather',
    //                 icon: 'icon-alert-circle',
    //                 color: 'danger'
    //             });
    //         });

    //     }
    // });
  },

  // Facebook Login
  loginWithFacebook({ dispatch, commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning",
      });
      return false;
    }
    const provider = new firebase.auth.FacebookAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (value) => {
        console.log(value);
        var res = value.user.displayName.split(" ");
        let userEmail = value.user.email || "";
        const userDetails = {
          email: userEmail,
          name: res[0],
          surname: res[1],
          telephone: "",
        };
        await dispatch(
          "employerModule/createUser",
          {
            cred: value,
            userDetails: userDetails,
          },
          { root: true }
        );
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
  },

  // Twitter Login
  loginWithTwitter({ dispatch, commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning",
      });
      return false;
    }
    const provider = new firebase.auth.TwitterAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (value) => {
        console.log(value);
        var res = value.user.displayName.split(" ");
        const userDetails = {
          email: "",
          name: res[0],
          surname: res[1],
          telephone: "",
        };
        await dispatch(
          "employerModule/createUser",
          {
            cred: value,
            userDetails: userDetails,
          },
          { root: true }
        );
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
  },

  // Github Login
  loginWithGithub({ commit, state }, payload) {
    if (state.isUserLoggedIn()) {
      payload.notify({
        title: "Login Attempt",
        text: "You are already logged in!",
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: "warning",
      });
      return false;
    }
    const provider = new firebase.auth.GithubAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        router.push(router.currentRoute.query.to || "/");
        result.user.providerData[0].uid = result.user.uid;
        commit("UPDATE_AUTHENTICATED_USER", result.user.providerData[0]);
      })
      .catch((err) => {
        payload.notify({
          time: 2500,
          title: "Error",
          text: err.message,
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
  }, */

  async registerUser({ dispatch }, payload) {
    this._vm.$vs.loading({ type: 'corners' });
    const auth = getAuth();
    await createUserWithEmailAndPassword(auth, payload.userDetails.email, payload.userDetails.password).then(
      async () => {
        this._vm.$vs.loading.close();
        // await this.generateActionLink(payload.userDetails.email, 'verify-email');
        await dispatch('generateActionLink', { email: payload.userDetails.email, linkType: 'verify-email' });
        return router.push({ name: 'verifyEmail', params: { emailSent: true } });
      },
      (error) => {
        this._vm.$vs.loading.close();
        console.log(error.message);
        if (error.message === 'Firebase: Error (auth/email-already-in-use).') {
          payload.notify({
            title: 'Oops!',
            text: 'That email is already in use.',
            color: 'warning',
          });
        } else {
          payload.notify({
            title: 'Error',
            text: error.message,
            color: 'danger',
          });
        }
      },
    );
  },
  updateUsername({ commit }, payload) {
    payload.user
      .updateProfile({
        displayName: payload.username,
      })
      .then(() => {
        // If username update is success
        // update in localstorage
        let newUserData = Object.assign({}, payload.user.providerData[0]);
        newUserData.displayName = payload.username;
        commit('UPDATE_AUTHENTICATED_USER', newUserData);

        // If reload is required to get fresh data after update
        // Reload current page
        if (payload.isReloadRequired) {
          // router.push(router.currentRoute.query.to || '/pages/dashboard');
          router.push('/pages/dashboard');
        }
      })
      .catch((err) => {
        payload.notify({
          time: 8800,
          title: 'Error',
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        });
      });
  },
  updateAuthenticatedUser({ commit }, payload) {
    commit('UPDATE_AUTHENTICATED_USER', payload);
  },
  async resetPassword(payload, data) {
    return new Promise((resolve, reject) => {
      try {
        const auth = getAuth();
        verifyPasswordResetCode(auth, data.oobCode)
          .then(async (email) => {
            await confirmPasswordReset(auth, data.oobCode, data.password);
            data.notify({
              time: 8800,
              title: 'Success',
              text: 'Password has been reset',
              // iconPack: 'feather',
              // icon: 'icon-alert-circle',
              color: 'success',
            });
            resolve(email);
          })
          .catch((error) => {
            reject(error);
            if (error.message == 'Firebase: Error (auth/invalid-action-code).') {
              data.notify({
                time: 8800,
                title: 'Oops!',
                text: 'This password reset link has been used or has expired. Request another password reset to change your password.',

                color: 'danger',
              });
            } else {
              reject(error);
              data.notify({
                time: 8800,
                title: 'Error',
                text: error.message,
                // iconPack: 'feather',
                // icon: 'icon-alert-circle',
                color: 'danger',
              });
            }
          });

        // signInWithEmailAndPassword(auth, email, data.password);
      } catch (error) {
        reject(error);
        data.notify({
          time: 8800,
          title: 'Error',
          text: error.message,
          // iconPack: 'feather',
          // icon: 'icon-alert-circle',
          color: 'danger',
        });
      }
    });
  },
  async forgotPassword({ dispatch }, payload) {
    // const auth = getAuth();
    this._vm.$vs.loading({ type: 'corners' });
    console.log(payload);
    return (
      dispatch('generateActionLink', { email: payload.email, linkType: 'reset-password' })
        // return sendPasswordResetEmail(auth, data.email)
        .then(() => {
          this._vm.$vs.loading.close();
          payload.notify({
            time: 5000,
            title: 'A link to reset your password has been sent to your email address',
            // iconPack: 'feather',
            // icon: 'icon-alert-circle',
            color: 'success',
          });
        })
        .catch((error) => {
          this._vm.$vs.loading.close();
          if (error.message === 'Firebase: Error (auth/user-not-found).' || error.message === 'Firebase: Error (auth/invalid-email).')
            return payload.notify({
              time: 2500,
              title: 'Error',
              text: 'No user with that email exists',
              // iconPack: 'feather',
              // icon: 'icon-alert-circle',
              color: 'danger',
            });

          if (error.message === 'too-many-requests')
            return payload.notify({
              time: 2500,
              title: 'Too fast!',
              text: 'Please wait a few moments before trying again.',
              // iconPack: 'feather',
              // icon: 'icon-alert-circle',
              color: 'warning',
            });

          payload.notify({
            time: 2500,
            title: 'Error',
            text: error.message,
            // iconPack: 'feather',
            // icon: 'icon-alert-circle',
            color: 'danger',
          });

          /*         console.log(error);
           */
        })
    );
  },

  logout({ commit }) {
    getAuth()
      .signOut()
      .then(() => {
        if (router.currentRoute.name !== 'pageLogin') {
          router.push({ path: '/pages/login' });
        }
        commit('UPDATE_AUTHENTICATED_USER', null);
      });
  },
  async setFirebaseAdmin({ commit }, user) {
    const docRef = collection(db, 'admins');
    const snapshot = await getDocs(docRef);
    // const snapshot = await firebase.firestore().collection('admins').get();
    const isAdmin = snapshot.docs.find((doc) => user.email === doc.data().email);
    if (isAdmin) {
      commit('SET_FIREBASE_ADMIN');
    }
  },

  async generateActionLink(store, payload) {
    if (!tooManyRequests()) return;

    await fetch(process.env.VUE_APP_GENERATE_ACTION_LINK, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: payload.email,
        linkType: payload.linkType,
        forMobile: false,
      }),
    });
    // process.env.VUE_APP_GENERATE_ACTION_LINK
    // http://localhost:5001/recreuitaguide-dev/us-central1/generateActionEmailLink
  },
};
