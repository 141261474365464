const counterModule = {
  firestorePath: 'counters',
  firestoreRefType: 'collection',
  moduleName: 'counterModule',
  statePropName: 'data',
  namespaced: true,

  state: {
    counters: {},
  },
  getters: {
    getCounters: (state) => {
      return state.data;
    },
  },
};

export default counterModule;
