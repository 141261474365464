/*=========================================================================================
  File Name: moduleAuthState.js
  Description: Auth Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// import firebase from 'firebase/app';
// import 'firebase/auth';
import { getAuth } from 'firebase/auth';

export default {
  firebaseAdmin: false,
  isUserLoggedIn: () => {
    let isAuthenticated = false;

    // get firebase current user
    const firebaseCurrentUser = getAuth().currentUser;

    if (firebaseCurrentUser) isAuthenticated = true;
    else isAuthenticated = false;

    return localStorage.getItem('userInfo') && isAuthenticated;
  },
};
