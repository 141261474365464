/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import App from './App.vue';
import PortalVue from 'portal-vue';
// Vuesax Component Framework
import Vuesax from 'vuesax';
import 'material-icons/iconfont/material-icons.css'; //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
import Multiselect from 'vue-multiselect';
import VueSkeletonLoading from 'vue-skeleton-loading';
import InstantSearch from 'vue-instantsearch';

import { getAuth, onAuthStateChanged } from 'firebase/auth';

import './plugins/integrations/analytics';

import 'firebase/analytics';

// Theme Configurations
import '../themeConfig.js';

// Globally Registered Components
import './globalComponents.js';

// Styles: SCSS
import './assets/scss/main.scss';

// Tailwind
import '@/assets/css/main.css';

// Vue Router
import router from './router';

// Vue Tour
import VueTour from 'vue-tour';

// Vue Analytics
// import VueAnalytics from 'vue-analytics';

// Vuex Store
import store from './store/store';

// Vuesax Admin Filters
import './filters/filters';

import VeeValidate from 'vee-validate';
/* import VeeValidate from "vee-validate";*/
// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer';

// PrismJS
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';

// Feather font icon
require('./assets/css/iconfont.css');

//sentry

import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

//VCalender

import VCalendar from 'v-calendar';

//  import { Integrations } from '@sentry/tracing';

// Sentry.init({
//   dsn: process.env.VUE_APP_SENTRY_DSN,
//   integrations: [
//     ...Sentry.defaultIntegrations,
//     new Vue({
//       Vue,
//       tracing: true,
//       logErrors: true,
//       tracingOptions: {
//         trackComponents: true,
//       },
//     }),
//     new Integrations.BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ['localhost', 'business.recruitaguide.com', /^\//],
//     }),
//   ],

//   // We recommend adjusting this value in production, or using tracesSampler
//   // for finer control
//   tracesSampleRate: process.env.VUE_APP_SAMPLE_RATE,
// });

Sentry.init({
  Vue,

  dsn: process.env.VUE_APP_SENTRY_DSN,
  integrations: [
    ...Sentry.defaultIntegrations,
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'business.recruitaguide.com', /^\//],
    }),

    // new Vue({
    //   Vue,
    //   tracing: true,
    //   logErrors: true,
    //   tracingOptions: {
    //     trackComponents: true,
    //   },
    // }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.VUE_APP_SAMPLE_RATE,
});

// register globally
Vue.use(InstantSearch);
Vue.use(VueTour);
require('vue-tour/dist/vue-tour.css');
Vue.use(Vuesax);
Vue.use(VueSkeletonLoading);
Vue.use(VueHammer);
Vue.use(VeeValidate);
Vue.use(PortalVue);
Vue.use(VCalendar, {
  componentPrefix: 'vc', // Use <vc-calendar /> instead of <v-calendar />
  // ...other defaults
});

/* Vue.component("ValidationProvider", ValidationProvider);*/
Vue.component('Multiselect', Multiselect);

// Vue.use(VueAnalytics, {
//   id: process.env.VUE_APP_MEASUREMENT_ID,
//   router,
// });

Vue.config.productionTip = false;

Vue.filter('formatPicture', function (value) {
  if (!value) return;
  if (value.includes('facebook')) {
    return value + '?width=500&heigth=500';
  } else if (value.includes('twimg')) {
    return value.replace('_normal', '');
  } else {
    return value;
  }
});

new Vue({
  router,
  store,
  created() {
    const auth = getAuth();
    onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        if (firebaseUser.isAnonymous) {
          return;
        }

        await firebaseUser.reload();
        this.$vs.loading({
          type: 'corners',
          background: 'rgba(255, 255, 255)',
          text: 'Please wait a moment while we load everything...',
        });

        // store.dispatch('userModule/openDBChannel');
        await store.dispatch('userModule/openDBChannel', {
          where: [['employers', 'array-contains', firebaseUser.uid]],
        });
        // .then(() => {
        //   this.$vs.loading.close();
        // });

        /*         .catch(console.error);
         */ await store.dispatch('notificationModule/openDBChannel', {
          where: [['employerId', '==', firebaseUser.uid]],
        });
        /*           .catch(console.error);
         */ await store.dispatch('jobsModule/openDBChannel', {
          where: [['employerId', '==', firebaseUser.uid]],
        });
        await store.dispatch('archivedJobsModule/openDBChannel', {
          where: [['employerId', '==', firebaseUser.uid]],
        });

        /*           .catch(console.error);
         */ await this.$store.dispatch('applicationsModule/openDBChannel', {
          where: [['employerId', '==', firebaseUser.uid]],
        });
        /*           .catch(console.error);
         */ await store.dispatch('chatModule/openDBChannel', {
          where: [['employerId', '==', firebaseUser.uid]],
        });
        /*           .catch(console.error);
         */ await store.dispatch('companiesModule/openDBChannel', {
          where: [['employerId', '==', firebaseUser.uid]],
        });
        /*           .catch(console.error);
         */ await store.dispatch('paymentModule/fetchAndAdd', {
          where: [['employer_id', '==', firebaseUser.uid]],
        });
        /*           .catch(console.error);
         */
        await store.dispatch('countriesModule/openDBChannel');
        /*         .catch(console.error);
         */ await store.dispatch('filtersModule/openDBChannel');
        await store.dispatch('userReportsModule/openDBChannel');
        await store.dispatch('referenceReportsModule/openDBChannel');
        await store.dispatch('forReviewModule/openDBChannel');
        await store.dispatch('counterModule/fetchAndAdd');

        this.$vs.loading.close();

        /*  .catch(console.error);
         */
        // if (this.$route.name === 'Profile') {
        //   this.$router.push('/pages/resume');
        // }
      } else {
        store.dispatch('employerModule/closeDBChannel', { clearModule: true });
        // store.dispatch('employersModule/closeDBChannel', { clearModule: true });
        store.dispatch('userReportsModule/closeDBChannel', { clearModule: true });
        store.dispatch('referenceReportsModule/closeDBChannel', { clearModule: true });
        store.dispatch('forReviewModule/closeDBChannel', { clearModule: true });
        store.dispatch('userModule/closeDBChannel', { clearModule: true });
        store.dispatch('companiesModule/closeDBChannel', { clearModule: true });
        store.dispatch('chatModule/closeDBChannel', { clearModule: true });
        store.dispatch('applicationsModule/closeDBChannel', {
          clearModule: true,
        });
        store.dispatch('jobsModule/closeDBChannel', {
          clearModule: true,
        });
        store.dispatch('archivedJobsModule/closeDBChannel', {
          clearModule: true,
        });
        store.dispatch('notificationModule/closeDBChannel', {
          clearModule: true,
        });
        store.dispatch('filtersModule/closeDBChannel', { clearModule: true });
        store.dispatch('countriesModule/closeDBChannel', { clearModule: true });
        store.dispatch('paymentModule/closeDBChannel', { clearModule: true });
      }
    });
  },
  render: (h) => h(App),
}).$mount('#app');
