const referenceReportsModule = {
  firestorePath: 'reports/references/references',
  firestoreRefType: 'collection',
  moduleName: 'referenceReportsModule',
  statePropName: 'data',
  namespaced: true,

  state: {
    referenceReports: {},
  },
  getters: {
    getReferenceReports: (state) => {
      return state.data;
    },
  },
};

export default referenceReportsModule;
