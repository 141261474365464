/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

const actions = {
  // ////////////////////////////////////////////
  // SIDEBAR & UI UX
  // ////////////////////////////////////////////
  togglePurchaseCredits({ commit }, payload) {
    commit('TOGGLE_PURCHASE_CREDITS', payload);
  },
  toggleGeneralPopup({ commit }, payload) {
    commit('TOGGLE_GENERAL_POPUP', payload);
  },
  togglePromotionModal({ commit }, payload) {
    commit('TOGGLE_PROMOTION_MODAL', payload);
  },
  toggleSidebar({ commit }, active) {
    commit('TOGGLE_SIDEBAR', active);
  },
  updateSidebarWidth({ commit }, width) {
    commit('UPDATE_SIDEBAR_WIDTH', width);
  },
  updateI18nLocale({ commit }, locale) {
    commit('UPDATE_I18N_LOCALE', locale);
  },
  toggleContentOverlay({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY');
  },
  updateTheme({ commit }, val) {
    commit('UPDATE_THEME', val);
  },
  updateUserRole({ commit }, val) {
    commit('UPDATE_USER_ROLE', val);
  },
  updateWindowWidth({ commit }, width) {
    commit('UPDATE_WINDOW_WIDTH', width);
  },

  // ////////////////////////////////////////////
  // COMPONENT
  // ////////////////////////////////////////////
  setActiveApp({ commit }, app) {
    commit('SET_ACTIVE_APP', app);
  },

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit('UPDATE_STARRED_PAGE', payload);
  },

  //  The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list);
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list);
  },

  // Google analytics
  sendGoogleEvent({ category, action, label, value = 0 }) {
    this.$ga.event({
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
    });
  },
};

export default actions;
