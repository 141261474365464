/* eslint-disable no-param-reassign */
const jobsModule = {
  firestorePath: 'jobs',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'jobsModule',
  statePropName: 'data',
  namespaced: true, // automatically added
  sync: {
    preventInitialDocInsertion: false,
    insertHookBeforeSync: function (updateFirestore, doc) {
      doc.jobId = doc.id;
      updateFirestore(doc);
    },
    patchHookBeforeSync: function (updateFirestore, doc) {
      doc.jobId = doc.id;
      updateFirestore(doc);
    },
    deleteHookBeforeSync: function (updateFirestore, id) {
      updateFirestore(id);
    },
    default: {
      expired: false,
      active: true,
      companyId: '',
      jobImage: '',
      jobBanner: '',
      compName: '',
      employerId: '',
      jobTitle: '',
      jobDescr: '',
      jobType: '',
      payTerm: '',
      location: '',
      guideType: '',
      secondaryGuideType: '',
      requiredQual: [],
      categories: [],
      jobDuration: '',
      jobDurationType: '',
      averageWorkHours: '',
      languages: [],
      experience: '',
      validFirstAid: '',
      visaRequirements: '',
      nationalities: [],
      validPDP: '',
      minSalary: '',
      maxSalary: '',
      itinerary: '',
      cities: '',
      nqfLevels: '',
      views: 0,
      planType: 0,
      created_at: Date.now(),
      updated_at: Date.now(),
      expire_at: Date.now(),
      invites: 20,
      id: '',
    },
  },

  serverChange: {
    addedHook: function (updateStore, doc, id, store) {
      store.commit('jobsModule/UPDATE_JOB_LIST', {
        id: id,
        title: doc.jobTitle,
      });
      updateStore(doc);
    },
    convertTimestamps: {
      created_at: '%convertTimestamp%', // default
      updated_at: '%convertTimestamp%', // default
      createdAt: '%convertTimestamp%', // default
      updatedAt: '%convertTimestamp%', // default
      expire_at: '%convertTimestamp%',
    },
  },

  // this object is your store module (will be added as '/myModule')
  // you can also add state/getters/mutations/actions
  state: {
    jobTitleArr: [],
  },
  getters: {
    getJobTotal: (state) => {
      return Object.keys(state.jobTitleArr).length;
    },
    getJobList: (state) => {
      return state.jobTitleArr;
    },
  },
  mutations: {
    UPDATE_JOB_LIST(state, { id, title }) {
      const job = {};
      job[id] = title;
      state.jobTitleArr.push(job);
    },
    CLEAR_JOB_LIST(state) {
      state.jobTitleArr = [];
    },
    ADD_JOB(state, job) {
      state.data.push(job);
    },
  },
  actions: {
    setJobList({ commit, getters }) {
      commit('CLEAR_JOB_LIST');
      this.jobs = Object.values(this.state.jobsModule.data).map(function (key) {
        const fields = key;
        let obj = {};
        for (const key of Object.keys(fields)) {
          obj[key] = fields[key];
        }
        return obj;
      });
      for (var job in this.jobs) {
        if (!getters['getJobList'].some((e) => Object.keys(e) == this.jobs[job].id)) {
          commit('UPDATE_JOB_LIST', {
            id: this.jobs[job].id,
            title: this.jobs[job].jobTitle,
          });
        } else {
          /*           console.log("Already in array");
           */
        }
      }
    },
  },
};

export default jobsModule;
