/* eslint-disable no-param-reassign */
/*=========================================================================================
  File Name: moduleChatMutations.js
  Description: Chat Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
/* import { increment } from "vuex-easy-firestore";
 */
export default {
  UPDATE_ABOUT_CHAT(state, obj) {
    obj.rootState.AppActiveUser.about = obj.value;
  },
  UPDATE_STATUS_CHAT(state, obj) {
    obj.rootState.AppActiveUser.status = obj.value;
  },
  SET_CHAT_SEARCH_QUERY(state, query) {
    state.chatSearchQuery = query;
  },
  SEND_CHAT_MESSAGE(state, payload) {
    if (payload) {
      const id = payload.applicationsId;
      state.data[id] = payload;
    } else {
      const chatId = payload.id;
      Vue.set(state.chats, [chatId], {
        isPinned: payload.isPinned,
        msg: [payload.msg],
      });
    }
  },
  TOGGLE_IS_PINNED(/* state, payload */) {
    // const chats = Object.entries(state.data).find(key => key[0] == payload.id);
    // chats[1].pinned = true;
    // state.data[payload] = chats;
    // console.log(chats);
    // .pinned = payload.value;
  },
  MARK_SEEN_ALL_MESSAGES(state, payload) {
    payload.chatData.msg.forEach((msg) => {
      msg.isSeen = true;
    });
  },
};
