const forReviewModule = {
  firestorePath: 'for_review/jobs/jobs',
  firestoreRefType: 'collection',
  moduleName: 'forReviewModule',
  statePropName: 'data',
  namespaced: true,

  state: {
    forReview: {},
  },
  getters: {
    getForReview: (state) => {
      return state.data;
    },
  },
};

export default forReviewModule;
