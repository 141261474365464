<template>
  <div>
    <vs-popup v-if="popupActive" title="Video Bio" :active.sync="popupActive">
      <div class="item">
        <video-player ref="videoPlayer" class="vjs-custom-skin" :options="playerOptions" :playsinline="true"> </video-player>
      </div>
    </vs-popup>

    <vs-list>
      <div>
        <!-- class="p-5 rounded-lg border-solid border-2 border-rag-green" -->

        <div v-if="input.nationality && input.nationality !== ''" class="vx-row">
          <div class="px-4 py-2">
            <p class="text-xs text-dark-grey">Nationality</p>
            <p class="item-name font-semibold my-1">
              {{ input.nationality || 'No nationality provided' }}
            </p>
          </div>
        </div>
        <div class="vx-row">
          <div class="p-4">
            <p class="text-xs text-dark-grey">About me</p>
            <p v-if="input.aboutYourself && input.aboutYourself !== ' ' && input.aboutYourself !== ''" class="item-name font-semibold my-1">
              {{ input.aboutYourself }}
            </p>
            <p v-else class="item-name font-semibold my-1"> No information provided by guide </p>
          </div>
        </div>
        <div class="vx-row">
          <div class="p-4">
            <p class="text-xs text-dark-grey">Why I am interested in the industry</p>
            <p v-if="input.whyThis && input.whyThis !== ' ' && input.whyThis !== ''" class="item-name font-semibold my-1">
              {{ input.whyThis }}
            </p>
            <p v-else class="item-name font-semibold my-1"> No information provided by guide </p>
          </div>
        </div>
        <div class="vx-row">
          <div class="p-4">
            <p class="text-xs text-dark-grey">My best guiding experience</p>
            <p
              v-if="input.bestExperience && input.bestExperience !== ' ' && input.bestExperience !== ''"
              class="item-name font-semibold my-1"
            >
              {{ input.bestExperience }}
            </p>
            <p v-else class="item-name font-semibold my-1"> No information provided by guide </p>
          </div>
        </div>
        <div v-if="input.profileVideo && input.profileVideo !== ''" class="mt-5" @click="openVideo">
          <p class="text-xs text-dark-grey mb-3">My video bio</p>
          <vx-card class="my-1 flex items-center justify-center cursor-pointer p-5 rounded-lg border-solid border-2 border-grey-light">
            <!-- <img src="@/assets/images/logo/logo.png" style="height: 100px" /> -->
            <feather-icon svg-classes="h-8 w-8" icon="PlayCircleIcon" />
          </vx-card>
        </div>
      </div>
    </vs-list>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import { videoPlayer } from 'vue-video-player';
export default {
  name: 'ApplicationUserContent',
  components: {
    videoPlayer,
  },
  props: {
    input: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      popupActive: false,
      playerOptions: {
        autoplay: false,
        muted: true,
        height: 600,
        // fluid: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: 'video/mp4',
            // mp4
            src: this.input.profileVideo,
            // webm
            // src: "https://cdn.theguardian.tv/webM/2015/07/20/150716YesMen_synd_768k_vp8.webm"
          },
        ],
        poster: '../../../assets/images/vid_placeholder.jpg',
      },
    };
  },
  // watch: {
  //   input: function (newVal, oldVal) {
  //     console.log('Prop changed: ', newVal, ' | was: ', oldVal);
  //   },
  //   deep: true,
  // },
  methods: {
    openVideo() {
      if (this.input.profileVideo) {
        this.popupActive = true;
      }
    },
  },
};
</script>

<style>
/* .con-vs-popup .vs-popup {
  height: 700px !important;
} */
</style>
