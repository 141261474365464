<script>
export default {
  name: 'ApplicationProfileTour',
  data() {
    return {
      applicationProfileTourSteps: [
        {
          target: '#profileUsername',
          header: {
            title: `<strong>This is an applicant's profile</strong>`,
          },
          content: `From here you can send them a message or handle their application.`,
          params: {
            enableScrolling: false,
            placement: 'auto-end',
          },
        },
        {
          target: '#appProfileMsgBtn',

          content: `You can contact an applicant directly through Recruit a Guide's built-in chat.<br><br>You can see all your active chats from the <strong>Chat Tab</strong> in the sidebar.`,
          params: {
            enableScrolling: false,
            placement: 'bottom-end',
          },
        },
        {
          target: '#appProfileActionBtns',

          content: `From this bar you can decline or shortlist an application, as well as delete an application after you have declined it.`,
          params: {
            enableScrolling: false,
            placement: 'top',
          },
        },
      ],
      guideProfileTourSteps: [
        {
          target: '#profileUsername',
          header: {
            title: `<strong>This is a guide's profile</strong>`,
          },
          content: `From here you can view their profile and invite them to apply for jobs.`,
          params: {
            enableScrolling: false,
            placement: 'auto-end',
          },
        },

        {
          target: '#inviteToJobBar',

          content: `From this bar you can invite guides to apply for any of your jobs. Inviting someone to a job will create an application under the <strong>Applications Tab</strong>.`,
          params: {
            enableScrolling: false,
            placement: 'top',
          },
        },
      ],
      appTourCallbacks: {
        onSkip: this.markAppTourSeen,
        onFinish: this.markAppTourSeen,
      },
      profileTourCallbacks: {
        onSkip: this.markProfileTourSeen,
        onFinish: this.markProfileTourSeen,
      },
    };
  },
  mounted() {
    const employer = this.$store.getters['employerModule/getUser'];
    if (!employer.dashboardTours || (!employer.dashboardTours.applicationProfileTourSeen && this.$route.name === 'ApplicationIn')) {
      setTimeout(() => {
        try {
          this.$tours['applicationProfileTour'].start();
        } catch (error) {
          return;
        }
      }, 1000);
    }
    if ((!employer.dashboardTours || !employer.dashboardTours.guideProfileTourSeen) && this.$route.path === '/pages/resume') {
      setTimeout(() => {
        try {
          this.$tours['guideProfileTour'].start();
        } catch (error) {
          return;
        }
      }, 1000);
    }
  },
  methods: {
    markAppTourSeen() {
      this.$store.dispatch('employerModule/patch', { dashboardTours: { applicationProfileTourSeen: true } });
    },
    markProfileTourSeen() {
      this.$store.dispatch('employerModule/patch', { dashboardTours: { guideProfileTourSeen: true } });
    },
  },
};
</script>
<template>
  <div>
    <v-tour name="guideProfileTour" :steps="guideProfileTourSteps" :callbacks="profileTourCallbacks"></v-tour>
    <v-tour name="applicationProfileTour" :steps="applicationProfileTourSteps" :callbacks="appTourCallbacks"></v-tour>
  </div>
</template>

<style lang="scss">
.v-step,
.v-step__header,
.v-step__arrow,
.v-step__arrow::before {
  background: #adca63 !important;
}
.v-step {
  border-radius: 10px !important;
  z-index: 99999 !important;
}
.v-step__header {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  margin-top: 0 !important;
}

.v-step__button {
  border-radius: 5px !important;
  border-top-width: 0.1rem !important;
  font-size: 1rem !important;
}
</style>
