<template>
  <div class="w-full mb-5">
    <vs-popup title="Qualification" :active.sync="popupActive">
      <img class="doc-image-full" :src="image" />
    </vs-popup>
    <!-- <button @click=""> TEST </button> -->
    <!--  -->

    <div class="py-2">
      <!-- <ul> -->
      <!-- :class="doc.doc === null || doc.doc === '' ? 'cursor-default' : 'cursor-pointer'" -->
      <vx-card v-for="(doc, index) in input" :key="index" class="mb-5 border-solid border-2 border-grey-light">
        <div class="sm:grid grid-cols-2 gap-4">
          <!-- <div class="cols-span-1"> -->
          <template v-if="doc.doc !== '' && doc.doc !== null">
            <img
              v-if="doc.type === 'Image'"
              class="doc-user-image rounded-lg cols-span-1 cursor-pointer"
              :class="{ 'blur-image': !hasApp }"
              :src="doc.doc"
              @click="[(image = doc.doc), openPopup(doc)]"
            />
          </template>
          <template v-if="(doc.doc === null || doc.doc === '') && doc.type !== 'application/pdf'">
            <img class="doc-no-image cols-span-1" src="https://i.imgur.com/gFUUFpR.png" />
          </template>
          <a
            v-if="doc.type === 'application/pdf' || doc.type === 'PDF' || doc.type === 'pdf'"
            :href="hasApp ? doc.doc : 'javascript:void(0);'"
            :target="hasApp ? '_blank' : ''"
            @click="hasApp ? null : noApp()"
          >
            <img
              class="rounded-lg doc-pdf-image cols-span-1 align-bottom"
              :class="{ 'blur-image': !hasApp }"
              src="https://ceresgs.com/wp-content/uploads/2016/07/PDF-Placeholder-240x300.png"
          /></a>
          <!-- </div> -->
          <div class="cols-span-1 sm:p-3 mt-4 sm:mt-0 flex flex-col justify-between">
            <div class="flex flex-col items-start"
              ><p class="qual-doc-text text-dark-grey font-semibold">{{ doc.title }}</p>
              <p :class="!doc.title || doc.title === '' ? 'font-semibold' : 'text-xs'" class="qual-doc-text text-dark-grey">{{
                doc.category
              }}</p>
              <p class="qual-doc-text text-xs text-grey">{{ doc.qualType }}</p>
              <p v-if="doc.qualCert && doc.qualCert !== 'null'" class="qual-doc-text text-xs text-grey">{{ doc.qualCert }}</p>
              <p v-if="doc.level && doc.level !== 'null'" class="qual-doc-text text-xs text-grey">{{ doc.level }}</p>
            </div>
            <div v-if="doc.expiryDate" class="flex flex-col justify-start">
              <p class="text-xs text-dark-grey">Expires at</p>
              <p class="qual-doc-text text-dark-grey font-semibold">{{ getDate(doc.expiryDate) }}</p></div
            >
            <div v-if="doc.issueDate" class="flex flex-col justify-start">
              <p class="text-xs text-dark-grey">Issued at</p>
              <p class="qual-doc-text text-dark-grey font-semibold">{{ getDate(doc.issueDate) }}</p></div
            >
          </div>
        </div>
      </vx-card>
      <!-- </ul> -->
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ApplicationQualifications',

  props: {
    input: {
      type: Array,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      popupActive: false,
      image: '',
      applications: this.$store.state.applicationsModule.data,
    };
  },
  computed: {
    hasApp() {
      return Object.values(this.applications).find((app) => {
        if (app.status !== 1) {
          return app.userId === this.userId;
        }
      });
    },
  },

  methods: {
    openPopup(image) {
      if (this.hasApp) {
        return image.doc ? (this.popupActive = true) : (this.popupActive = false);
      }
      return this.noApp();
    },
    noApp() {
      this.$store.dispatch('toggleGeneralPopup', {
        active: true,
        title: 'Application required',
        text: "To view this guide's documentation, they need to either accept an invite to one of your job posts or apply to one of your jobs themselves.",
        buttonText: 'Okay',
        click: () => {
          this.$store.dispatch('toggleGeneralPopup', { active: false });
        },
      });
    },
    getDate(timeStamp) {
      let date;
      if (timeStamp._seconds) {
        date = new Date(timeStamp._seconds * 1000);
      }
      if (timeStamp.seconds) {
        date = new Date(timeStamp.seconds * 1000);
      }
      return moment(date.toISOString()).format('DD/MM/YYYY');
    },
  },
};
</script>

<style lang="scss">
.doc-no-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.doc-pdf-image {
  width: 100%;
  height: 15rem;
  object-fit: cover;
}

.doc-user-image {
  width: 100%;
  height: 15rem;
  object-fit: cover;
}

.blur-image {
  filter: blur(14px);
  -webkit-filter: blur(14px);
}

@media (min-width: 576px) {
  .doc-image-full {
    margin-left: 50px;
  }
}
.doc-image-full {
  width: 80%;
  margin-left: 37px;
}
.qual-doc-text {
  text-align: left;
}
</style>
