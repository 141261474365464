const userReportsModule = {
  firestorePath: 'reports/profiles/profiles',
  firestoreRefType: 'collection',
  moduleName: 'userReportsModule',
  statePropName: 'data',
  namespaced: true,

  state: {
    userReports: {},
  },
  getters: {
    getUserReports: (state) => {
      return state.data;
    },
  },
};

export default userReportsModule;
