<template>
  <div ref="index" class="shadow-default z-10 rounded-lg border-2 border-solid border-grey-light p-1 relative mb-3">
    <div class="w-full flex justify-between">
      <div class="progress"></div>
      <div class="flex items-center justify-items-center ml-2">
        <div class="material-icons mr-1"> mic </div>
        <div>
          <p class="item-name font-semibold"> {{ language.language || language }}</p>
          <p class="text-xs text-dark-grey"> {{ language.level || 'No proficiency select' }}</p>
        </div>
      </div>

      <div v-if="language.doc" class="flex items-center justify-items-center mr-4">
        <div class="material-icons mr-1 cursor-pointer" @click="toggleAudio(language.doc)">
          {{ audioIcon }}
        </div>
        <div class="material-icons mr-1 cursor-pointer" @click="stopAudio"> stop_circle </div>
      </div>
      <div v-else class="flex items-center justify-items-center mr-4">
        <p class="text-xs text-dark-grey"> No recording provided</p>
      </div>
    </div>
  </div>
</template>

<script>
import { Howl } from 'howler';

export default {
  props: {
    language: { type: Object, default: null },
    index: { type: Number, default: 0 },
  },

  data() {
    return {
      audioPlaying: false,
      audioLoading: false,
      audio: null,
    };
  },
  computed: {
    audioIcon() {
      if (this.audioPlaying) {
        return 'pause_circle_outline';
      } else return 'play_circle_outline';
    },
  },
  methods: {
    step() {
      let seek;
      if (this.audio) {
        seek = this.audio.seek() || 0;
      }
      const progress = this.$refs.index.querySelector('.progress');
      progress.style.width = ((seek / this.audio.duration()) * 100 || 0) + '%';
      if (this.audio.playing()) {
        requestAnimationFrame(this.step);
      }
    },
    stopAudio() {
      if (this.audio) {
        this.audio.stop();
        this.audioPlaying = false;
      }
    },
    toggleAudio(doc) {
      if (!this.audio) {
        this.audio = new Howl({
          src: doc,
          html5: true,
          onplay: () => {
            requestAnimationFrame(this.step);
          },
        });
      }

      if (!this.audio.playing()) {
        this.audio.play();
        this.audioPlaying = true;
      } else {
        this.audio.pause();
        this.audioPlaying = false;
      }

      this.audio.on('end', this.stopAudio);
    },
    // playAudio(audio) {
    //   audio.addEventListener('canplaythrough', () => {
    //     audio.play();
    //     this.audioLoading = false;
    //     this.audioPlaying = true;
    //   });
    //   audio.addEventListener('ended', () => {
    //     console.log('ended');
    //   });
    // },
    // pauseAudio(audio) {
    //   audio.pause();

    //   this.audioPlaying = false;
    // },
    endAudio() {},
  },
};
</script>

<style scoped>
.progress {
  position: absolute;
  border-radius: 0.4rem;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  z-index: -1;
  background-color: rgba(173, 202, 99, 0.6);
}
</style>
