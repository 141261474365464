/*=========================================================================================
  File Name: moduleChatGetters.js
  Description: Chat Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import contacts from '@/views/apps/chat/contacts';

export default {
  chatDataOfUser: (state) => (id) => {
    return state.data[id];
  },
  chats: (state) => {
    if (state.data !== null && state.data !== undefined) {
      const chatArray = Object.entries(state.data).filter((contact) => {
        return contact[1].userData.userName.toLowerCase().includes(state.chatSearchQuery.toLowerCase()) && contact[1].messages.length > 0;
      });
      return chatArray.sort((x, y) => {
        let timeX = x[1].updatedAt;
        let timeY = y[1].updatedAt;
        return timeY - timeX;
      });
    }
  },
  chatcontacts: (state) =>
    contacts.filter((contact) => {
      return contact.name.toLowerCase().includes(state.chatSearchQuery.toLowerCase());
    }),
  chatLastMessaged: (state, getters) => (id) => {
    if (getters.chatDataOfUser(id)) return getters.chatDataOfUser(id).messages.slice(-1)[0];
    else return false;
  },
  chatUnseenMessages: (state, getters) => (id) => {
    let unseenMsg = 0;
    const chatData = getters.chatDataOfUser(id);

    if (chatData) {
      // chatData.messages.map(msg => {
      if (chatData.lastSentBy !== chatData.employerId) unseenMsg++;
      // });
    }
    return unseenMsg;
  },
};
