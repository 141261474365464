<template>
  <div class="grid grid-cols-1 py-2">
    <div class="col-span-1">
      <vx-card v-for="(ref, index) in input" :key="index" class="mb-5 border-solid border-2 border-grey-light">
        <div class="px-2 sm:flex w-full">
          <div class="-ml-1 sm:ml-0 material-icons text-4xl mr-5"> work_outline </div>

          <div>
            <div class="flex justify-between">
              <div>
                <h6 class="item-name font-semibold mb-1">
                  {{ ref.title }}
                </h6>
                <p class="text-sm mb-2">
                  {{ ref.company }}
                </p>
                <p class="text-sm text-grey">
                  {{ getDate(ref.fromDate) }} - <span v-if="!ref.current">{{ getDate(ref.toDate) }}</span>
                  <span v-if="ref.current">Present ({{ dateDifference(ref.fromDate) }})</span>
                  <span v-else> ({{ dateDifference(ref.fromDate, ref.toDate) }})</span>
                </p>
              </div>
            </div>
            <p class="font-medium mt-2">
              {{ ref.description }}
            </p>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'ApplicationJobHistory',
  props: {
    input: {
      type: Array,
      required: true,
    },
  },
  methods: {
    dateDifference(fromDateT, toDateT) {
      let fromDate;
      let toDate;
      let dateDiff;
      if (fromDateT._seconds) {
        fromDate = new Date(fromDateT._seconds * 1000);
      } else if (fromDateT.seconds) {
        fromDate = new Date(fromDateT.seconds * 1000);
      }
      if (!toDate) {
        const currentDate = moment(new Date());
        dateDiff = currentDate.diff(fromDate, 'years', true).toFixed(2);
      } else {
        if (toDateT._seconds) {
          toDate = new Date(toDateT._seconds * 1000);
        } else if (toDateT.seconds) {
          toDate = new Date(toDateT.seconds * 1000);
        }
        dateDiff = fromDate.diff(toDate, 'years', true).toFixed(2);
      }

      const years = dateDiff.split('.')[0];
      const months = Math.round((12 * +dateDiff.split('.')[1]) / 100);

      const yearText = +years > 1 ? 'yrs' : 'yr';
      const monthText = months > 1 ? 'mos' : 'mo';
      if (years === '0' && months === 0) {
        return `Less than a month`;
      } else if (years === '0' && months >= 1) {
        return `${months} ${monthText}`;
      } else return `${years} ${yearText} ${months} ${monthText}`;
    },
    getDate(timeStamp) {
      let date;
      if (timeStamp._seconds) {
        date = new Date(timeStamp._seconds * 1000);
      }
      if (timeStamp.seconds) {
        date = new Date(timeStamp.seconds * 1000);
      }
      return moment(date.toISOString()).format('DD/MM/YYYY');
    },
  },
};
</script>

<style>
.vx-card__collapsible-content {
  padding-bottom: 0px !important;
}
</style>
