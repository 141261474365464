/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import VuexEasyFirestore from 'vuex-easy-firestore';

Vue.use(Vuex);

import moduleAuth from './auth/moduleAuth.js';

import { Firebase, initFirebase } from '../firebase/firebaseConfig';

import employerModule from './firebase/employerModule/moduleEmployer';
// import employersModule from './firebase/employerModule/employersModule';
import notificationModule from './firebase/notificationModule/notificationModule';
import jobsModule from './firebase/jobsModule/jobsModule';
import archivedJobsModule from './firebase/archivedJobsModule/archivedJobsModule';

import chatModule from './chat/moduleChat';
import applicationsModule from './firebase/applicationsModule/applicationsModule';
import countriesModule from './firebase/countriesModule/countriesModule';
import filtersModule from './firebase/filtersModule/filtersModule';
import userReportsModule from './firebase/userReportsModule/userReportsModule';
import referenceReportsModule from './firebase/referenceReportsModule/referenceReportsModule';
import forReviewModule from './firebase/forReviewModule/forReviewModule';
import userModule from './firebase/userModule/userModule';
import companiesModule from './firebase/companyModule/companyModule';
import paymentModule from './firebase/paymentModule/paymentModule';
import counterModule from './firebase/counterModule/counterModule';

const easyFirestore = VuexEasyFirestore(
  [
    employerModule,
    counterModule,
    // employersModule,
    notificationModule,
    userReportsModule,
    forReviewModule,
    referenceReportsModule,
    archivedJobsModule,
    jobsModule,
    chatModule,
    applicationsModule,
    countriesModule,
    filtersModule,
    userModule,
    companiesModule,
    paymentModule,
  ],
  {
    logging: false,
    FirebaseDependency: Firebase,
  },
);

const storeData = {
  getters,
  mutations,
  state,
  actions,
  plugins: [easyFirestore],
  modules: {
    auth: moduleAuth,
    chat: chatModule,
  },
  strict: false,
};

const store = new Vuex.Store(storeData);

initFirebase();
// .then(() => {
//   console.log(messaging, 'message');
//   if (messaging && messaging.onMessage) {
//     return messaging.onMessage((payload) => {
//       store.commit('UPDATE_MESSAGE', payload);
//     });
//   } else return;
// })
// .catch((error) => {
//   // if (this.$router) {
//   //   this.$router.push('/pages/error-404');
//   // }
//   throw error;
// });

export default store;
